import React from 'react';
import Layout from '../components/Layout/Layout';
import { Helmet } from 'react-helmet';
import './donate.scss';

const Donate = () => {
  return (
    <Layout>
      <Helmet>
        <script
          type="text/javascript"
          id="embed_js"
          src="https://apps.letthemshineusa.org/html/common/themes/embeddedArreva.js?RmlJeUZrREl5VTIvZG5QajdRY0hHNkl3QVR0TW8vVWExTnlsRkhBSWxYT0l4Z3c3NWdhaG1SQ2hwU2k2Ny92bVBmUGJmTW1DVmlLL3lCelVuYm5SSHFaU2l4MjE3ZmMvcEEybldIVnF6eXJpVkVuQmFleWsrQ2NHNXI4NWFEa2w="
        ></script>
      </Helmet>
      <div className="donate">
        <div id="arreva-div"></div>
      </div>
    </Layout>
  );
};

export default Donate;
